<template>
	<div class="page df fdc aic w100vw">
		<div class="steps-content">
			<div style="font-weight: bold;">收货人信息</div>
			<div class="addressList df">
				<div @click="changeAddress(item.id)" class="item por" :class="{active:addressId==item.id}"
					v-for="(item,index) in addressList">
					<div class="top df aic">
						<span>{{item.real_name}}</span>
						<span class="phone">{{item.phone}}</span>
					</div>
					<div class="bottom por">
						<span>{{item.detail}}</span>
						<div class="operate df poa">
							<div class="edit">修改</div>
							<div class="del">删除</div>
						</div>
					</div>
					<div class="default df jcc aic poa" v-if="item.is_default">默认地址</div>
					<div v-if="addressId==item.id" class="checkd poa">
					</div>
					<a-icon style="position: absolute;right: 0;bottom: 0;color: #fff;" type="check" />
				</div>
			</div>
			<a-button @click="$go('/orderSystem/myAddress')" class="button" icon="plus-circle" type="default">
				新增收货人信息
			</a-button>
			<a-table rowKey="id" :pagination="false" class="table" :columns="columns" :data-source="data">
				<img slot="image" slot-scope="text" :src="text"></img>
				<div slot="info" slot-scope="data">
					<div v-html="data"></div>
				</div>
				<div slot="price" slot-scope="data">
					<span style="color: #CE0000;">￥{{data}}</span>
				</div>
			</a-table>
			<div style="font-weight: bold;margin: 10px;margin-top: 30px;" class="df aic">
				<span>添加订单备注</span>
				<a-input v-model="params.mark" style="width: 500px;margin-left: 10px;"
					placeholder="限45字（定制类商品，请将购买需求在备注中做详细说明）" allow-clear />
			</div>
			<a-divider />
			<a-select placeholder="使用优惠券" style="width: 120px">
				<a-select-option value="lucy">
					暂无
				</a-select-option>
			</a-select>
			<div class="row df jcfe">
				件商品，总商品金额：¥{{productMoney}}
			</div>
			<div class="row df jcfe">
				运费：{{totalPostage?'¥'+totalPostage:'免邮'}}
			</div>
			<div class="bottom-bar df fdc jcc aife">
				<div>
					<span>应付总额：</span>
					<span style="color: #D5232F;">¥{{productMoney}}</span>
				</div>
				<div v-if="address"> 寄送至： {{address.detail}} 收货人：{{address.real_name}} {{address.phone}} </div>
			</div>
		</div>
		<div class="steps-action df jcfe">
			<a-button style="width: 200px;height: 50px;" @click="createOrder" type="primary">
				提交订单
			</a-button>
		</div>
	</div>
</template>

<script>
	const columns = [{
			title: '商品图片',
			dataIndex: 'image',
			key: 'image',
			scopedSlots: {
				customRender: 'image'
			},
		},
		{
			title: '商品信息',
			dataIndex: 'product_name',
			key: 'product_name',
			scopedSlots: {
				customRender: 'product_name'
			},
		},
		{
			title: '单价',
			dataIndex: 'price',
			key: 'price',
			ellipsis: true,
			scopedSlots: {
				customRender: 'price'
			},
		},
		{
			title: '数量',
			dataIndex: 'num',
			key: 'num',
			ellipsis: true,
		},
		{
			title: '规格',
			dataIndex: 'key_name',
			key: 'key_name',
			ellipsis: true,
		},
	];

	export default {
		data() {
			return {
				data: [],
				columns,
				productMoney: null,
				totalPostage: null,
				address: null,
				addressList: [],
				params: {
					orderKey: '',
					mark: '',
					payType: 'native',
					couponId: ''
				},
				addressId: '',
			};
		},
		mounted() {
			let {
				orderKey
			} = this.$route.query
			this.params.orderKey = orderKey
			this.addressLst()
			setTimeout(()=>this.confirmOrder(),500)
			
		},
		methods: {
			changeAddress(id) {
				this.addressId = id
				this.confirmOrder()
			},
			async addressLst() {
				let {
					result
				} = await this.$api.addressLst()
				this.addressList = result
				this.addressList.forEach(v => this.addressId = v.is_default ? v.id : this.addressList[0].id)
			},
			async createOrder() {
				let {
					pay,
					order_id
				} = await this.$api.createOrder(this.params)
				pay = pay.substring(pay.lastIndexOf('/') + 1, pay.length)
				this.$go(`/payOrder?pay=${pay}&order_id=${order_id}`)
			},
			async confirmOrder() {
				let {
					productList,
					productMoney,
					totalPostage,
					address,
					orderKey: key
				} = await this.$api.confirmOrder({
					orderKey: this.params.orderKey,
					addressId: this.addressId
				})
				this.productMoney = productMoney
				this.totalPostage = totalPostage
				this.data = productList
				this.address = address
				this.params.orderKey = key
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/common/scss/turn.scss';

	.page {
		width: 1200px;

		.steps-content {
			border-radius: 6px;

			.addressList {
				.item {
					cursor: pointer;
					font-size: px2rem(12);
					font-weight: bold;
					padding: 0 px2rem(16);
					margin-left: 10px;
					margin-top: px2rem(14px);
					width: px2rem(282px);
					height: px2rem(142);
					border: 2px solid #DADADA;

					.top {
						border-bottom: 1px solid #DADADA;
						height: px2rem(38);

						.phone {
							margin-left: px2rem(48);
						}
					}

					.bottom {
						height: calc(100% - 38px);
						margin-top: 8px;

						.operate {
							bottom: px2rem(11px);

							.del {
								margin-left: px2rem(35);
							}
						}
					}

					.default {
						color: #fff;
						right: 0;
						top: 0;
						width: px2rem(62px);
						height: px2rem(20px);
						background: #DADADA;
					}

					.checkd {
						right: 0;
						bottom: 0;
						width: 0;
						height: 0;
						border-bottom: px2rem(18) solid red;
						border-left: px2rem(18) solid transparent;
					}
				}

				.active {
					border: 2px solid #D5232F;
				}
			}

			.button {
				margin-left: px2rem(39px);
				margin-top: px2rem(20px);
			}

			.table {
				margin-top: px2rem(50);

				img {
					width: px2rem(123);
					height: px2rem(123);
				}
			}

			.row {
				font-weight: bold;
				margin-bottom: px2rem(42);
			}

			.bottom-bar {
				padding-right: 17px;
				font-weight: bold;
				background: #F3F3F3;
				width: 100%;
				height: px2rem(80);
			}
		}

		.steps-action {
			margin: px2rem(50) 0;
		}
	}
</style>
